import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ceroAGuion',

})
export class CeroAGuionPipe implements PipeTransform {
  constructor(private pipe: DecimalPipe){}
  transform(value: any, args?: any): any {
    if(value == 0){
      return '-';
    }
    return this.pipe.transform(value,'1.0-3','en-US')
  }
}
