import { Routes } from '@angular/router';
import { appRoutesNames } from './app.routes.names';
import { RoleGuardService } from './auth/role-guard.service';
import { HomeComponent } from './home/home.component';

export const APP_ROUTES: Routes = [
  //Normal Loading
  //{ path: appRoutesNames.DASHBOARD, children: DASHBOARD_ROUTES },
  //Lazy Loading
  { path: 'home', component: HomeComponent },

  // EQUIPOS DINAMICOS
  {
    path: appRoutesNames.EQUIPOS_DINAMICOS, loadChildren: './equipos-dinamicos/equipos-dinamicos.module#EquiposDinamicosModule'
  },
  // VISOR ROUTES
  {
    path: appRoutesNames.VISOR, loadChildren: './visor/visor.module#VisorModule', canActivate: [RoleGuardService], data: {
      expectedLevel: 20,
      expectedRoles: []
    }
  },
  // ADMINISTRACION
  {
    path: appRoutesNames.ADMINISTRACION, loadChildren: './administracion/administracion.module#AdministracionModule', canActivate: [RoleGuardService], data: {
      expectedLevel: 20,
      expectedRoles: []
    }
  },
  // MANTENEDORES
  {
    path: appRoutesNames.MANTENEDORES, loadChildren: './mantenedores/mantenedores.module#MantenedoresModule', canActivate: [RoleGuardService], data: {
      expectedLevel: 20,
      expectedRoles: []
    }
  },
  // MONITOREO
  {
    path: appRoutesNames.MONITOREO, loadChildren: './monitoreo/monitoreo.module#MonitoreoModule', canActivate: [RoleGuardService], data: {
      expectedLevel: 20,
      expectedRoles: []
    }
  },
  // SOPORTE
  {
    path: appRoutesNames.SOPORTE, loadChildren: './soporte/soporte.module#SoporteModule', canActivate: [RoleGuardService], data: {
      expectedLevel: 20,
      expectedRoles: []
    }
  },
  // DEFAULT
  { path: '**', redirectTo: 'home' }
]
