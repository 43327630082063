import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { RoleGuardService } from '../auth/role-guard.service';
import { DialogCargadorService } from '../equipos-dinamicos/services/dialog-cargador-service.service';
import { AddSubZone } from '../models/enums/addSubZone.enum';
import { ReturnFormat } from '../models/enums/returnFormat.enum';
import { SnackBarColor } from '../models/enums/snackBarColor.enum ';
import { empresa } from '../models/mantenedores/empresa.interface';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  metroPolTerminal = {
    id: 16,
    nombre: "STP",
    empresas_id: 11,
    created_at: "2020-08-09T00:52:50.399Z",
    updated_at: "2020-08-09T00:52:50.399Z",
    direcciones_id: 30,
    latitud: -33.5223342,
    longitud: -70.8014514,
    configs_ocpp_id: 1
  }
  metroPolCargador = {
    id: 148,
    identificador_equipo: 16000034,
    nombre: "STP_E06",
    tipos_funcionalidades_id: 1,
    potencia_maxima: 200,
    imei: 16000034,
    estado: 1,
    latitud: null,
    longitud: null,
    tipos_equipos_id: 19,
    mangueras: [
      {
        id: 98,
        nombre: "Manguera",
        numero_manguera: 2,
        estado: true,
        ultimos_datos_mangueras: {
          id: 1508641,
          potencia_dc: 76,
          voltaje_dc: 681.4,
          corriente_dc: 112.3,
          mangueras_id: 98,
          created_at: "2022-08-12T08:06:14.000Z",
          updated_at: "2022-08-12T08:06:14.000Z",
          soc: 97,
          estado: null,
          energia_activa: null,
          temperatura: 65
        },
        actual_alarmas_manguera: {
          id: 1296342,
          codigo_error: "NoError",
          estado: "Available",
          mangueras_id: 98,
          created_at: null,
          updated_at: "2022-08-12T14:31:34.000Z",
          vendor_error: "",
          vendor_id: "",
          info: ""
        },
        tipos_cables_id: 2
      },
      {
        id: 97,
        nombre: "Manguera",
        numero_manguera: 1,
        estado: true,
        ultimos_datos_mangueras: {
          id: 1508639,
          potencia_dc: 81,
          voltaje_dc: 678.5,
          corriente_dc: 120.4,
          mangueras_id: 97,
          created_at: "2022-08-12T10:47:11.000Z",
          updated_at: "2022-08-12T10:47:11.000Z",
          soc: 98,
          estado: null,
          energia_activa: null,
          temperatura: 66
        },
        actual_alarmas_manguera: {
          id: 1296341,
          codigo_error: "NoError",
          estado: "Available",
          mangueras_id: 97,
          created_at: null,
          updated_at: "2022-08-12T14:28:06.000Z",
          vendor_error: "",
          vendor_id: "",
          info: ""
        },
        tipos_cables_id: 1
      }
    ],
    tipos_cargador: {
      id: 2,
      nombre: "Cargador Terminal",
      estado: 1
    },
    terminales_id: 16,
    tipos_protocolos: {
      id: 1,
      nombre: "Ocpp",
      created_at: null,
      updated_at: null
    },
    vin: null,
    alias_equipo: null,
    tipos_equipos: {
      id: 19,
      nombre: "MCD"
    },
    empresas: {
      id: 11,
      nombre: "COPEC",
      rut: "906900009",
      contacto: "copec@dhemax.cl",
      region_horaria: "America/Santiago",
      id_padre: 0
    },
    direcciones: {
      id: 9,
      descripcion: "Región Metropolitana, Chile",
      referencia: "Prueba",
      comunas: {
        id: 296,
        nombre: "Santiago",
        created_at: null,
        updated_at: null,
        regiones_id: 16
      }
    },
    actual_gps: null
  }
  metroPolBus = {
    id: 100,
    identificador_equipo: 41359769,
    nombre: "BUS 78",
    imei: 357784041359769,
    created_at: "2020-06-21T04:57:20.889Z",
    updated_at: "2021-06-18T07:17:56.879Z",
    tipos_equipos_id: 25,
    estado: 1,
    id_equipo_padre: null,
    tipos_cargador_id: null,
    empresas_id: 10,
    potencia_maxima: null,
    lineas_id: null,
    posicion_linea: null,
    direcciones_id: 29,
    terminales_id: 14,
    tipos_funcionalidades_id: 4,
    latitud: null,
    longitud: null,
    tipos_protocolos_id: 4,
    estado_conexion: 1,
    vin: "LC06S44R9L4000005",
    eficiencia: 1,
    simid: "89598073101051999154",
    version_fw: "2.3.5",
    alias_equipo: null,
    tipos_electricos_id: null,
    tipos_modelos_equipos_id: null
  }

  constructor(
    private snackBar: MatSnackBar,
    private rGuard: RoleGuardService,
    private dService: DialogCargadorService,
    private jwt: JwtHelperService,
    private toast: ToastrService,

  ) { }

  formatearFechaFull = (fecha, conDesfase: AddSubZone, region_horaria: string, returnFormat: ReturnFormat) => {
    const d = new Date(fecha);

    if (region_horaria != "") {


      const tz = (moment.tz.zone(region_horaria).utcOffset(d.getTime())); // formato:  180 o 240
      const desfase = tz / 60 * 1;

      if (conDesfase == AddSubZone.Add) {

        d.setHours(d.getHours() + desfase);

      } else if (conDesfase == AddSubZone.Sub) {

        d.setHours(d.getHours() - desfase);
      } else if (conDesfase == AddSubZone.None) {
        //do nothing

      }
    }
    this.getDateFormattedString(returnFormat);


    const yyyy = d.getFullYear();
    const MM = String(d.getMonth() + 1).padStart(2, '0'); //Enero es 0
    const dd = String(d.getDate()).padStart(2, '0');
    const HH = String(d.getHours()).padStart(2, '0');
    const mm = String(d.getMinutes()).padStart(2, '0');
    const ss = String(d.getSeconds()).padStart(2, '0');
    switch (returnFormat) {
      case ReturnFormat.yyyyMMdd_HHmmss:
        return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`
        break;
      case ReturnFormat.yyyyddMM_HHmmss:
        return `${yyyy}-${dd}-${MM} ${HH}:${mm}:${ss}`
        break
      case ReturnFormat.MMddYYYY_HHmmss:
        return `${MM}-${dd}-${yyyy} ${HH}:${mm}:${ss}`
        break;
      case ReturnFormat.ddMMyyyy_HHmmss:
        return `${dd}-${MM}-${yyyy} ${HH}:${mm}:${ss}`
        break;
      case ReturnFormat.MMdd_HHmmssm:
        return `${MM}-${dd} ${HH}:${mm}:${ss}`
        break
      //CORTOS
      case ReturnFormat.ddMMyyyy:
        return `${dd}-${MM}-${yyyy}`
        break
      case ReturnFormat.HHmmss:
        return `${HH}:${mm}:${ss}`
        break
      case ReturnFormat.MMddyyyy:
        return `${MM}-${dd}-${yyyy}`
        break
      case ReturnFormat.yyyyMMdd:
        return `${yyyy}-${MM}-${dd}`
        break
      case ReturnFormat.MMyyyy:
        return `${MM}-${yyyy}`
        break
    }
  }
  showMeSnack(mensaje, snackbarColor: SnackBarColor) {

    this.snackBar.open(mensaje, 'OK', {
      duration: 2500,
      panelClass: [snackbarColor],
      verticalPosition: 'bottom',
      horizontalPosition: 'center'
    });

  }
  async GetEmpresa() {
    let empresaSelectedID = this.rGuard.empresaAccess();
    return new Promise((res, rej) => {
      this.dService.getEmpresa(empresaSelectedID).subscribe((e: empresa) => {
        res(e);
      })
    });
  }
  getDateFormattedString(returnFormat: ReturnFormat) {
    for (let i = 0; i < returnFormat.toString().length; i++) {
      const element = returnFormat.toString()[i];


    }
  }
  groupBy(xs, key) {
    let temp_dic = {};
    temp_dic = xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    let return_array = [];
    for (const [key, value] of Object.entries(temp_dic)) {
      let item = {
        key,
        data: value,
      }
      return_array.push(item);
    }
    return return_array;
  };
  getTokenPayload() {
    const token: any = localStorage.getItem('access_token');
    const tokenPayload = this.jwt.decodeToken(token);
    return tokenPayload;
  }
  /**
   *
   * @param message the message
   * @param success success or nah
   */
  showToast(message: string, success: boolean = false) {
    if (success) {
      this.toast.success(message);
    } else {
      this.toast.error(message)
    }
  }
}
