import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GeocodeService } from 'src/app/sharedServices/geocode.service';
declare const google: any;

@Component({
  selector: 'app-ubicacion-actual-equipo',
  templateUrl: './ubicacion-actual-equipo.component.html',
  styleUrls: ['./ubicacion-actual-equipo.component.scss']
})
export class UbicacionActualEquipoComponent implements OnInit {

  geoCoder;
  waypoint: any;
  lat;
  lng;
  rad;
  zoom: number = 16;
  direccion: any = "Cargando direccion...";
  nombre: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private gCService: GeocodeService,
    private ref: ChangeDetectorRef) { }


  ngOnInit() {

    // this.geoCoder = new google.maps.Geocoder();
    this.waypoint = this.data.gps;
    this.lat = this.waypoint.latitud;
    this.lng = this.waypoint.longitud;
    this.rad = this.waypoint.radio;
    this.nombre = this.waypoint.nombre;
 

  
    this.gCService.reverseGeocodeAddress(this.lat, this.lng).subscribe(val => {

      this.direccion = val.address;
      this.ref.detectChanges();
    });
  }



  getAddress(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0] != null) {
          this.direccion = results[0].formatted_address;

        } else {
          this.direccion = "Direccion no encontrada"
        }
      }
    }
    );
  }

  getCoord(query: string) {
    var lt, lg;
    return new Promise((resolve, reject) => {
      this.geoCoder.geocode({ 'address': query }, (result, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          lt = result[0].geometry.location.lat()
          lg = result[0].geometry.location.lng()
       


        }
      }
      );
    });
  }

}
