import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { JwtHelperService } from '@auth0/angular-jwt';
import { waypoints } from '../models/waypoints.interface';
import { WaypointCliente } from '../models/waypointCliente.interface';
import { Rutas } from '../models/rutas.interface';
import { WaypointSalidaRuta } from '../models/waypointSalidaRuta.interface';
import { unidadesBlindadas } from '../models/unidadesBlindadas.interface';
import { UnidadesBlindadasCompleto } from '../models/unidadesBlindadasCompleto.interface';
import { tripulacion } from '../models/tripulacion.interface';


@Injectable({
    providedIn: 'root'
})
export class SharedService {


    constructor(private snackBar: MatSnackBar, public jwtHelper: JwtHelperService) { }


    idUserActual(): number {
        const token = localStorage.getItem('access_token');
        const tokenPayload = this.jwtHelper.decodeToken(token);
        return tokenPayload.user_id;
    }

    switchBotones(data) {
        switch (data) {
            case 0:
                return "N"
            case 1:
                return "P"
            default: {
                return "-"
            }
        }
    }

    switchEstadoConexion(data) {
        switch (data) {
            case 1:
                return "NORMAL"
            // break;
            case 2:
                return "SIN CONEXION"
            // break;
            case 3:
                return "ALARMA"
            // break;
            default:
                return "SIN REGISTRO"
            // break;
        }
    }

    switchPuertas(data) {
        switch (data) {
            case 0:
                return "CERRADA"
            // break;
            case 1:
                return "ABIERTA"
            // break;
        }
    }

    processActualesFaltantes(list: UnidadesBlindadasCompleto[]) {
        for (let index = 0; index < list.length; index++) {
            list[index].estado = this.switchEstadoConexion(list[index].estado);
            if (list[index].actual_gps == null) {
                list[index].actual_gps = {
                    id: 0,
                    velocidad: 0,
                    latitud: '-',
                    longitud: '-',
                    estado_bateria: 0,
                }
            }
            if (list[index].actual_sensores == null) {
                list[index].actual_sensores = {
                    id: 0,
                    boton_cabina: '-',
                    boton_preboveda: '-',
                    chapa_contacto: '-',
                    corta_corriente: '-',
                    presencia_chofer: '-',
                    puerta_boveda: '-',
                    puerta_cabina: '-',
                    puerta_preboveda: '-'
                }
            } else {
                list[index].actual_sensores.boton_cabina = this.switchBotones(list[index].actual_sensores.boton_cabina);
                list[index].actual_sensores.boton_preboveda = this.switchBotones(list[index].actual_sensores.boton_preboveda);
                list[index].actual_sensores.puerta_cabina = this.switchPuertas(list[index].actual_sensores.puerta_cabina);
                list[index].actual_sensores.puerta_boveda = this.switchPuertas(list[index].actual_sensores.puerta_boveda);
                list[index].actual_sensores.puerta_preboveda = this.switchPuertas(list[index].actual_sensores.puerta_preboveda);
                if (list[index].actual_sensores.corta_corriente == 0) { list[index].actual_sensores.corta_corriente = "INACTIVO" }
                else { list[index].actual_sensores.corta_corriente = "ACTIVO"; }
                if (list[index].actual_sensores.presencia_chofer == 0) { list[index].actual_sensores.presencia_chofer = "PRESENTE" }
                else { list[index].actual_sensores.presencia_chofer = "AUSENTE"; }
                if (list[index].actual_sensores.chapa_contacto == 0) { list[index].actual_sensores.chapa_contacto = "APAGADO" }
                else { list[index].actual_sensores.chapa_contacto = "ENCENDIDO"; }
            }
        }
        return list;
    }

    processActualesFaltantesAVC(list: UnidadesBlindadasCompleto[]) {

        list.forEach(unidad => {

            unidad.estado = this.switchEstadoConexion(unidad.estado);
            if (unidad.actual_gps == null) {
                unidad.actual_gps = {
                    id: 0,
                    velocidad: 0,
                    latitud: '-',
                    longitud: '-',
                    estado_bateria: 0,
                }
            }
            if (unidad.actual_estados_salida == null) {
                unidad.actual_estados_salida = {
                    id: 0,
                    s1: '-',
                    s2: '-',
                    s3: '-',
                    s4: '-',
                    s5: '-',
                    s6: '-',
                    s7: '-',
                    s8: '-',
                    s9: '-',
                    s10: '-',
                    s11: '-',
                    s12: '-',
                    s13: '-',
                    s14: '-',
                    s15: '-',
                    s16: '-'
                }
            } else {
                unidad.actual_estados_salida.s1 = this.switchBotones(unidad.actual_estados_salida.s1);
                unidad.actual_estados_salida.s2 = this.switchBotones(unidad.actual_estados_salida.s2);
                unidad.actual_estados_salida.s3 = this.switchBotones(unidad.actual_estados_salida.s3);
                unidad.actual_estados_salida.s4 = this.switchBotones(unidad.actual_estados_salida.s4);
                unidad.actual_estados_salida.s5 = this.switchBotones(unidad.actual_estados_salida.s5);
                unidad.actual_estados_salida.s6 = this.switchBotones(unidad.actual_estados_salida.s6);
                unidad.actual_estados_salida.s7 = this.switchBotones(unidad.actual_estados_salida.s7);
                unidad.actual_estados_salida.s8 = this.switchBotones(unidad.actual_estados_salida.s8);
                unidad.actual_estados_salida.s9 = this.switchBotones(unidad.actual_estados_salida.s9);
                unidad.actual_estados_salida.s10 = this.switchBotones(unidad.actual_estados_salida.s10);
                unidad.actual_estados_salida.s11 = this.switchBotones(unidad.actual_estados_salida.s11);
                unidad.actual_estados_salida.s12 = this.switchBotones(unidad.actual_estados_salida.s12);
                unidad.actual_estados_salida.s13 = this.switchBotones(unidad.actual_estados_salida.s13);
                unidad.actual_estados_salida.s14 = this.switchBotones(unidad.actual_estados_salida.s14);
                unidad.actual_estados_salida.s15 = this.switchBotones(unidad.actual_estados_salida.s15);
                unidad.actual_estados_salida.s16 = this.switchBotones(unidad.actual_estados_salida.s16);
            }
        });
        


        return list;
    }

    processRutasRegion(list: Rutas[], region) {
        let listProcesada = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].regiones.id == region) {
                listProcesada.push(list[i])
            }
        }
        return listProcesada;
    }



    processWaypoints(list: waypoints[], comuna) {
        let listProcesada = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].direcciones.comunas.id == comuna) {

                listProcesada.push(list[i])
            }
        }
        return listProcesada;
    }

    processComunaRegion(region, comunas: any[]) {
        let listProcesada = [];
        for (let i = 0; i < comunas.length; i++) {
            if (comunas[i].regiones_id == region) {
                listProcesada.push(comunas[i])
            }
        }
        return listProcesada;
    }

    processEquipoSinLinea(equipos: any[]) {
        let listProcesada = [];
        for (let i = 0; i < equipos.length; i++) {
            if (equipos[i].lineas_id == null) {
                listProcesada.push(equipos[i])
            }
        }
        return listProcesada;
    }

    processEquiposBase(list: UnidadesBlindadasCompleto[], base) {
        let listProcesada = [];
        list.forEach(x => {
            if (x.ubicaciones_id === base && x.tipos_equipos_id != 5) {
                listProcesada.push(x);
            }
        });
        return listProcesada;
    }

    processEquiposBaseEscolta(list: UnidadesBlindadasCompleto[], base) {
        let listProcesada = [];
        list.forEach(x => {
            if (x.ubicaciones_id === base && x.tipos_equipos_id == 5) {
                listProcesada.push(x);
            }
        });
        return listProcesada;
    }

    processEquiposMultiplesBases(list: UnidadesBlindadasCompleto[], bases: any[]) {
        let listProcesada = [];
        list.forEach(x => {
            bases.forEach(b => {
                if (x.ubicaciones_id === b.id) {
                    listProcesada.push(x);
                }
            })
            // if (x.ubicaciones_id === bases) {
            //     listProcesada.push(x);
            // }
        });
        return listProcesada;
    }

    processTripulacion(list: tripulacion[], tipoUnico, tipoMultiple) {
        let listProcesada = [];
        list.forEach(x => {
            if (x.tipos_personas_id === tipoUnico || x.tipos_personas_id === tipoMultiple) {
                listProcesada.push(x);
            }
        });
        return listProcesada;
    }


    processContadorRFID(ruta: Rutas) {
        // var listS = [];
        // var listB = [];
        let subidas: any;
        let bajadas: any;
        for (let index = 0; index < ruta.detalles_rutas.length; index++) {
            let listS = [];
            let listB = [];
            ruta.detalles_rutas[index].logs_rfid_wp.forEach(element => {
                if (element.acciones_rfid_id == 1) {
                    listS.push(element.tarjeta_rfid_id)
                } else if (element.acciones_rfid_id == 2) {
                    listB.push(element.tarjeta_rfid_id)
                }
            });
            subidas = new Set(listS);
            bajadas = new Set(listB);
            ruta.detalles_rutas[index].cont_subidas = subidas.size;
            ruta.detalles_rutas[index].cont_bajadas = bajadas.size;
        }
        return ruta;
    }

    processWaypointsRuta(list: Rutas) {
        let listProcesada = [];
        list.detalles_rutas.forEach(x => listProcesada.push(x.waypoints));
        return listProcesada;
    }

    processWaypointsSalidaRuta(list: Rutas) {
        let listProcesada = [];
        list.detalles_rutas.forEach(x => {
            let detalleWaypoint = {
                nombre: '',
                clase: '',
                clave: '',
                radio: '',
                aperturas: '',
                cliente: ''
            };
            detalleWaypoint.nombre = x.waypoints.nombre;
            detalleWaypoint.clave = x.waypoints.clave;
            detalleWaypoint.radio = x.waypoints.radio;
            detalleWaypoint.clase = x.clases_waypoints.nombre;
            detalleWaypoint.cliente = x.clientes.nombre;
            detalleWaypoint.aperturas = x.waypoints.aperturas_max;

            listProcesada.push(detalleWaypoint)

        });
        return listProcesada;
    }

    processWaypointsClienteComuna(list: WaypointCliente[], comuna) {
        let listProcesada = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].comunas_id == comuna) {

                listProcesada.push(list[i])
            }
        }
        return listProcesada;
    }

    processTarjeta(list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].tarjetas_id == null) {
                list[i].tarjetas_id = "-";
            }
        }
        return list;
    }

    processEstado(list) {
        for (let i = 0; i < list.length; i++) {
            // list[i].id_original = list[i].id;
            list[i].show = true;
            if (list[i].estado == 1) {
                list[i].estado_mod = "Habilitado";
            } else {
                list[i].estado_mod = "Deshabilitado";
            }
        }
        return list;
    }

    processEstadoBoolean(list) {
        for (let i = 0; i < list.length; i++) {
            // list[i].id_original = list[i].id;
            if (list[i].estado) {
                list[i].estado_mod = "Habilitado";
            } else {
                list[i].estado_mod = "Deshabilitado";
            }
        }
        return list;
    }

    processEstadoBooleanVisorEquipos(list) {
        let processList = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].estado) {
                list[i].estado_mod = "Habilitado";
            } else {
                list[i].estado_mod = "Deshabilitado";
            }
            if (list[i].actual_gps != null) {
                processList.push(list[i]);
            }
        }
        return processList;
    }

    displayMessage(msj: string) {
        this.snackBar.open(msj, 'OK', {
            duration: 4500,
            panelClass: ['blue-snackbar'],
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }

}
