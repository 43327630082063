import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, retry } from 'rxjs/operators';
import { globalApi } from 'src/app/globalApi';

@Injectable({
    providedIn: 'root'
})
export class GpsService {
    uri = globalApi.uri;

    extractData(res: Response) {
        let body = res.json();
        return body || {};
    }
    handleErrorPromise(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
    public header: any;
    public host: any;
    public token: any;
    public options: any;
    isLoggedin: boolean;

    constructor(private http: HttpClient) {
        this.token = localStorage.getItem('access_token');
        this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
        this.options = { headers: this.header };

    }

    getClientes(): Observable<any> {
        return this.http.get(this.uri + "/clientes", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getWaypoints(): Observable<any> {
        return this.http.get(this.uri + "/waypoints", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }
    nuevoWaypoint(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevoWaypoint", data, this.options)
    }

    editaWaypoint(data: any): Observable<any> {
        return this.http.post(this.uri + "/editaWaypoint", data, this.options)
    }

    nuevoWpCliente(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevoWpCliente", data, this.options)
    }


    getWayPointsCLientes(): Observable<any> {
        return this.http.get(this.uri + "/wpClientes", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getWaypointsCliente(id): Observable<any> {
        return this.http.get(this.uri + "/wpClientes/" + id, this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getRutas(): Observable<any> {
        return this.http.get(this.uri + "/rutas", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    nuevaRuta(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevaRuta", data, this.options)
    }

    nuevaDireccion(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevaDireccion", data, this.options)
    }

    editaDireccion(data: any): Observable<any> {
        return this.http.post(this.uri + "/editaDireccion", data, this.options)
    }


    getDireccionesCliente(id): Observable<any> {
        return this.http.get(this.uri + "/direccionesCliente/" + id, this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getRegiones(): Observable<any> {
        return this.http.get(this.uri + "/getRegiones", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getComunas(id): Observable<any> {
        return this.http.get(this.uri + "/getComunasRegion/" + id, this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getTiposWaypoints() {
        return this.http.get(this.uri + "/tiposWaypoints", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getClasesWaypoints() {
        return this.http.get(this.uri + "/clasesWaypoints", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }



}