import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'table-template',
  templateUrl: './table-template.component.html',
  styleUrls: ['./table-template.component.scss']
})
export class TableTemplateComponent implements OnInit {
  @Input() dataSource;
  @Input() hasEdit;
  @Input() hasCustom;
  @Input() showsID: boolean = false;
  @Input('') customName: string;


  @Output() editEvent = new EventEmitter<string>();
  @Output() customEvent = new EventEmitter<string>();


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  columns = [];
  displayedColumns;
  constructor() { }

  ngOnInit() {

    for (const property in this.dataSource[0]) {
      if(!this.showsID){
        if (property != 'id') {
          this.columns.push({ columnDef: property, header: property });
        }
      }else{
        this.columns.push({ columnDef: property, header: property });
      }
    }
    this.displayedColumns = this.columns.map(c => c.columnDef);

    setTimeout(() => {
      this.dataSource.paginator = this.paginator.toArray()[0];
    }, 100);
  }
  handleEdit(data) {
    this.editEvent.emit(data);
  }
  handleCustom(data) {
    this.customEvent.emit(data);
  }

}
