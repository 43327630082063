import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { MatSnackBar, MatDialog } from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { User } from "../models/user";
import { RolesService } from "src/app/mantenedores/services/roles.service";
import { NavBarComponent } from "src/app/ui/nav-bar/nav-bar.component";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    loginForm: FormGroup;
    public error: string;
    ruta: any;
    loadDialog: any;
    reloading = false;
    views = {
        equipos: null,
        informes: null,
        graficos: null,
        buses: null,
        administracion: null,
        configuracion: null,
        mantenedores: null,
        soporte: null,
        programaHorarios: null
    };
    
    mainView = null;

    @ViewChild("loadingDialog") loadingModal: TemplateRef<any>;

    constructor(
        private auth: AuthService,
        private router: Router,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private rservice: RolesService,
        private rutas: NavBarComponent
    ) {}

    ngOnInit() {
        this.clearViews();
        //Forms
        this.loginForm = new FormGroup({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.minLength(2)),
        });

        if (this.auth.loggedIn()) {
            this.redirectByUser();
            /* 
      this.reloading = true;
      this.mostrarLoading();
      this.reloading = false;
      this.cerrarLoading(); */
        } else {
            this.router.navigate(["home"]);
        }
    }

    submit(model: User) {
        this.mostrarLoading();

        this.auth
            .login(model.email, model.password)
            .pipe(first())
            .subscribe(
                (result) => {
                    this.redirectByUser();

                    this.cerrarLoading();
                    //location.reload();
                },
                (err) => {
                    this.cerrarLoading();
                    this.snackBar.open("Credenciales Incorrectas", "OK", {
                        duration: 2500,
                        verticalPosition: "top",
                        horizontalPosition: "center",
                        panelClass: ["red-snackbar"],
                    });
                }
            );
    }

    isAuthenticated() {
        if (localStorage.getItem("access_token")) {
            return true;
        } else {
            return false;
        }
    }

    async redirectByUser() {
        
        // Obtenemos el token del almacenamiento local
        const token = localStorage.getItem("access_token");
        if (!token) {
            // Si no hay token, redireccionamos a home
            this.router.navigateByUrl("home");
            return;
        }
        
        // Decodificamos la información del token
        const tknInfo = this.auth.jwtHelper.decodeToken(token);
        
        // Solicitamos las vistas de roles basadas en el rol del token
        this.rservice.getRoleViews(tknInfo.role).subscribe(
            (val) => {
                // Definimos las categorías de vistas para filtrar y ordenar
                const viewCategories = [
                    'equipos', 'informes', 'graficos', 'buses',
                    'administracion', 'configuracion', 'mantenedores', 'soporte', 'programaHorario'
                ];
        
                // Filtramos y ordenamos las vistas para cada categoría
                viewCategories.forEach(category => {
                    this[category] = this.filterAndSortViews(val.data.role_vistas, category);
                });
        
                // Establecemos las rutas habilitadas
                this.rutas.setRutasHabilitadas();
        
                // Determinamos a qué ruta navegar en base a las vistas disponibles
                this.navigateBasedOnViews();
                
                // Cerramos el cargador y realizamos la navegación
                this.cerrarLoading();
                this.router.navigate([this.ruta]);
            },
            (err) => {
                // En caso de error en el endpoint, redireccionamos a home
                this.router.navigateByUrl("home");
            }
        );
    }
    
    // Filtra las vistas basadas en la categoría dada y luego las ordena
    filterAndSortViews(views, category: string) {
        const filteredViews = views.filter(view => view.parent === category);
        return this.sortViews(filteredViews);
    }
    
    // Ordena las vistas alfabéticamente
    sortViews(views) {
        return views.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
    }
    
    // Lógica para determinar a qué ruta navegar en base a las vistas disponibles
    navigateBasedOnViews() {
        const viewPriorityMap = [
            {view: 'equipos', routes: 'rutasEquipos', priority: 'mapaCargadores'},
            {view: 'informes', routes: 'informes', priority: 'informeCargasCargador'},
            {view: 'graficos', routes: 'rutasGraficos', priority: ''},
            {view: 'buses', routes: 'rutasBus', priority: 'monitoreoBuses'},
            {view: 'administracion', routes: 'rutasAdministracion', priority: ''},
            {view: 'configuracion', routes: 'rutasConfiguracion', priority: ''},
            {view: 'mantenedores', routes: 'rutasMantenedores', priority: ''},
            {view: 'soporte', routes: 'rutasSoporte', priority: ''},
            {view: 'programaHorario', routes: 'rutasProgramaHorario', priority: ''}
        ];
        
        // Itera a través del mapeo de prioridades para determinar la vista a la que navegar
        for (const mapping of viewPriorityMap) {
            if (this[mapping.view].length > 0) {
                this.getMainView(
                    this[mapping.view],
                    this.rutas[mapping.routes],
                    mapping.priority
                );
                return;
            }
        }
    }
    
    // Obtiene la vista principal para la navegación
    getMainView(views, routes, priorityView: string): void {
        // Busca la vista prioritaria o toma la primera vista como predeterminada
        const priority = views.find(element => element.nombre === priorityView) || views[0];
        // Filtra las rutas correspondientes
        const mainView = routes.filter(item => item.nombre === priority.nombre);
        this.mainView = mainView;
        this.ruta = mainView[0].ruta.substring(1);
        
    }
    
    // Limpia todas las categorías de vistas
    clearViews() {
        // Limpia todas las categorías de vistas
        for (let category in this.views) {
            this.views[category] = null;
        }
    }    
    

    mostrarLoading() {
        this.loadDialog = this.dialog.open(this.loadingModal, {
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            hasBackdrop: false,
        });
    }

    cerrarLoading() {
        this.rutas.botonActivo = 0;
        this.rutas.botonActivoSub = 0;
        this.rutas.botonActivoMain3 = false;
        this.rutas.botonActivoMain2 = false;
        if (this.loadDialog) {
            this.loadDialog.close();
        }
    }
}
