import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { globalApi } from "../globalApi";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthService {
    private accessTokenSubject = new BehaviorSubject<string | null>(
        localStorage.getItem("access_token")
    );
    accessToken$ = this.accessTokenSubject.asObservable();

    uri = globalApi.uri;
    constructor(private http: HttpClient, public jwtHelper: JwtHelperService) {}
    /**
     * Funcion de login que de ser correcto guarda el token del usuario en localstorage
     * @param email
     * @param password
     */
    login(email: string, password: string): Observable<boolean> {
        return this.http
            .post<{ access_token: string }>(this.uri + "/auth/login", {
                email: email,
                password: password,
            })
            .pipe(
                map((result) => {
                    localStorage.setItem(
                        "access_token",
                        result.access_token || ""
                    );
                    this.accessTokenSubject.next(result.access_token);
                    return true;
                })
            );
    }
    /**
     * Funcion para salir del sistema
     */
    logout() {
        localStorage.removeItem("access_token");
        this.accessTokenSubject.next(null);
    }
    /**
     * Funcion que valida si el usuario aun tiene los privilegios para estar en el sistema
     * por medio de la duracion del token que tiene asignado
     */
    loggedIn(): boolean {
        // Implementa la lógica para verificar si el usuario está autenticado
        // Devuelve true si está autenticado, de lo contrario, false
        // Por ejemplo, verifica si hay un token en el localStorage
        const token = localStorage.getItem("access_token");
        return !!token && !this.jwtHelper.isTokenExpired(token);
    }
}
