import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatSnackBar } from '@angular/material';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router, public jwtHelper: JwtHelperService, private snackBar: MatSnackBar) { }
    /**
     * Funcion basica para revisar que el usuario este correctamente validado y dar acceso a rutas *  
     * 
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.loggedIn()) {
            return true;
        } else {
            this.router.navigate(['home']).then(() => {
                this.snackBar.open('NO TIENE ACCESO', 'OK', {
                    duration: 2500,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            });
            return false;
        }
    }
}