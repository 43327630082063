import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { timeout, retry, catchError } from 'rxjs/operators';
import { globalApi } from 'src/app/globalApi';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    uri = globalApi.uri;

    extractData(res: Response) {
        let body = res.json();
        return body || {};
    }
    handleErrorPromise(error: Response | any) {
        return Observable.throw(error.message || error);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
      }


    public header: any;
    public host: any;
    public token: any;
    public options: any;
    isLoggedin: boolean;

    constructor(private http: HttpClient) {
        this.token = localStorage.getItem('access_token');
        this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
        this.options = { headers: this.header };

    }

    insertRole(data: any): Observable<any> {
        this.refreshToken();
        return this.http.post(this.uri + "/insertNewRol", data, this.options).pipe(
            catchError(this.handleError)
          );
    }

    editRole(data: any): Observable<any> {
        this.refreshToken();
        return this.http.post(this.uri + "/editRolViews", data, this.options).pipe(
            catchError(this.handleError)
          );
    }
    deleteRol(data: any): Observable<any> {
        this.refreshToken();
        const params = new HttpParams().set('roles_id', data.roles_id)
        return this.http.post(this.uri + "/deleteRol", data, this.options).pipe(
            catchError(this.handleError)
          );
    }   

    getAllRoles(): Observable<any> {
        this.refreshToken();
        return this.http.get(this.uri + "/getAvailableRolesAndViews?plataforma="+'pge').pipe(
            retry(4),
            timeout(10000),
            catchError(this.handleError)
        )
    }
    
    getRole(data:any) :Observable<any> {
        this.refreshToken();
        const params = new HttpParams().set('id', data)
        return this.http.post(this.uri + "/getRol", params, this.options).pipe(
            retry(4),
            timeout(10000),
            catchError(this.handleError)
        )
    }

    getRoleViews(data:any) :Observable<any> {
        this.refreshToken();
        const params = new HttpParams().set('id', data)
        return this.http.post(this.uri + "/getRolVistas", params, this.options).pipe(
            retry(4),
            timeout(10000),
            //catchError(this.handleError)
        )
    }

    refreshToken(){
        this.token = localStorage.getItem('access_token');
        this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
        this.options = { headers: this.header };
    }

    getRoleAndViews(): Observable<any> {
        return this.http.get(this.uri + "/getAvailableRolesAndViews?plataforma="+'pge').pipe(
            retry(4),
            timeout(10000)
        )
    }

    getAllRolesVistas(): Observable<any> {
        return this.http.get(this.uri + "/getAvailableRolesAndViews?plataforma="+'pge').pipe(
            retry(4),
            timeout(10000)
        )
    }


}