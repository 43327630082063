import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, retry } from 'rxjs/operators';
import { globalApi } from 'src/app/globalApi';

@Injectable({
    providedIn: 'root'
})
export class EquipoUsuariosService {
    uri = globalApi.uri;

    extractData(res: Response) {
        let body = res.json();
        return body || {};
    }
    handleErrorPromise(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
    public header: any;
    public host: any;
    public token: any;
    public options: any;
    isLoggedin: boolean;

    constructor(private http: HttpClient) {
        this.token = localStorage.getItem('access_token');
        this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
        this.options = { headers: this.header };

    }

    getEmpresas(): Observable<any> {
        return this.http.get(this.uri + "/empresas", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    registrarUsuario(data: any): Observable<any> {
        return this.http.post(this.uri + "/auth/register", data, this.options)
    }

    actualizaUsuario(data: any): Observable<any> {
        return this.http.post(this.uri + "/auth/update", data, this.options)
    }
    actualizaPerfil(data: any): Observable<any> {
        return this.http.post(this.uri + "/auth/editarperfil", data, this.options)
    }

    getUsuarios(): Observable<any> {
        return this.http.get(this.uri + "/usuarios", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }
    getDatosUsuarios(): Observable<any> {
        return this.http.get(this.uri + "/datosUsuarios", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    nuevaPersona(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevaPersona", data, this.options)
    }

    editaPersona(data: any): Observable<any> {
        return this.http.post(this.uri + "/editaPersona", data, this.options)
    }

    getTripulacion(): Observable<any> {
        return this.http.get(this.uri + "/tripulacion", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getAvatar(id): Observable<any> {
        return this.http.get(this.uri + "/personaAvatar/" + id, this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    editaAvatar(id, data: any): Observable<any> {
        return this.http.post(this.uri + "/editaAvatar/" + id, data, this.options)
    }


    getTiposPersonas(): Observable<any> {
        return this.http.get(this.uri + "/tiposPersonas", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getRoles(): Observable<any> {
        return this.http.get(this.uri + "/roles?plataforma="+'pge', this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }
    subirImagen(id, data: any): Observable<any> {
        return this.http.post(this.uri + "/subirImagen/" + id, data, this.options)
    }

    getImagen(id): Observable<any> {
        return this.http.get(this.uri + "/getImagen/" + id, this.options)
    }

}