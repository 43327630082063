import { AfterViewInit, Component, OnInit, Renderer2, ViewChild, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { RoleGuardService } from "src/app/auth/role-guard.service";
import {
    MatSnackBar,
    MatBottomSheet,
    MatExpansionModule,
    MatExpansionPanel,
    MatMenuTrigger
} from "@angular/material";
import { BottomSheetDescripcionComponent } from "src/app/equipos-dinamicos/bottom-sheet-descripcion/bottom-sheet-descripcion.component";
import { DatePipe } from "@angular/common";
import { EquipoUsuariosService } from "src/app/mantenedores/equipo/services/equipo-usuarios.service";
import { AuthService } from "src/app/auth/auth.service";
import { NavMenuItemInterface } from "../../models/navMenuItem.interface";
import { DialogCargadorService } from "../../equipos-dinamicos/services/dialog-cargador-service.service";
import { GlobalFunctionsService } from "../../sharedServices/global-functions.service";
import { RolesService } from "src/app/mantenedores/services/roles.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ConsumoElectricoBusesComponent } from "src/app/equipos-dinamicos/consumo-electrico-buses/consumo-electrico-buses.component";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export interface RutasUrl {
    texto?: string;
    nombre: string;
    ruta: string;
    icon: string;
    status: any;
}

export const menuParents = {
    EQUIPOS: "equipos",
    GRAFICOS: "graficos",
    BUSES: "buses",
    ADMINISTRACION: "administracion",
    CONFIGURACION: "configuracion",
    MANTENEDORES: "mantenedores",
    SOPORTE: "soporte",
    PROGRAMA_HORARIO: "programaHorario",
    INFORMES: "informes",
};

@Component({
    selector: "app-nav-bar",
    templateUrl: "./nav-bar.component.html",
    styleUrls: ["./nav-bar.component.scss"],
    providers: [DatePipe],
})
export class NavBarComponent implements OnInit {
    // Para el año en el footer
    now = new Date();

    // Para definir qué puede ver el usuario
    parents = menuParents;
    menuItems: NavMenuItemInterface[] = [];

    // Array de rutas de la web
    rutasEquipos: RutasUrl[]; // EQUIPOS
    rutasGraficos: RutasUrl[]; // GRÁFICOS
    rutasBus: RutasUrl[]; //BUSES
    rutasAdministracion: RutasUrl[]; // ADMINISTRACIÓN
    rutasConfiguracion: RutasUrl[]; // ADMINISTRACIÓN => CONFIGURACIÓN
    rutasMantenedores: RutasUrl[]; // MANTENEDORES
    rutasSoporte: RutasUrl[]; // SOPORTE
    rutasProgramaHorario: RutasUrl[]; // SOPORTE => PROGRAMA HORARIO
    informes: RutasUrl[]; // INFORMES

    baseImg = "/assets/img/logo_dhemax_2.png";
    imgUrl: any;
    empresaID;
    fullUri;
    botonActivo: number = null;
    botonActivoMain: any = null;
    botonActivoSub: any = null;
    accordionExpanded: boolean = false;
    showSubmenu: boolean = false;
    showSubsubmenu: boolean = false;

    vistas: any;
    vistas_usuario: any;
    rolesVistas: any;
    botonActivoMain2: boolean;
    botonActivoMain3: boolean;
    showMenu: boolean=false;
    menu1: any;
    modulesList: Array<any>;
    enteredButton = false;
    isMatMenuOpen = false;
    isMatMenu2Open = false;
    prevButtonTrigger;
    zIndexValue: number;
    disableSalir: any;

    constructor(
        public router: Router,
        private snackBar: MatSnackBar,
        private rGuard: RoleGuardService,
        private bottomSheet: MatBottomSheet,
        private eUserService: EquipoUsuariosService,
        private auth: AuthService,
        private dService: DialogCargadorService,
        private gf: GlobalFunctionsService,
        private rolesService: RolesService,
        private spinner: NgxSpinnerService
    ) {}

    ngAfterViewInit(): void {
        addEventListener;
    }

    ngOnInit() {
        // OBTENER VISTAS DEL USER
        this.menuItems= [];
        this.setRutasHabilitadas();
        this.auth.accessToken$.subscribe((token) => {
            if (token) {
                let eid = this.gf.getTokenPayload().empresas_id;
                let baseUri = "/assets/img/logos-empresas/";
                this.fullUri = baseUri + eid + ".png";
                this.empresaID = this.rGuard.empresaAccess();
                //TODO: get roles relationships and put them into the function
                //this.dService.getRolesVistas().subscribe(res => {
                this.obtenerMenuUsuario(
                    this.rGuard.roleId(),
                    this.rGuard.empresaId()
                );
                if (this.menuItems) {
                    this.setRutasHabilitadas();
                }
            } else {
            }
        });

    }

    // Funciones Menus y submenus Activos
    seleccionarBoton(numeroBoton: number) {
        this.botonActivo = numeroBoton;
        setTimeout(() => {
          this.zIndexValue = 1000
        }, 2500);
    }
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  
  
    menuenter() {
      setTimeout(() => {
  
          this.isMatMenuOpen = true;
          if (this.isMatMenu2Open) {
              this.zIndexValue = 1001;
            this.isMatMenu2Open = false;
          }else{
              this.zIndexValue = 1001
          }
      }, 100);
    }
  
    menuLeave(trigger, button) {
      setTimeout(() => {
      if(this.isMatMenuOpen){
          this.zIndexValue= 1001
  
      }
      }, 1000)
    }
  
  
    buttonEnter(trigger) {
      setTimeout(() => {
  
        if(this.prevButtonTrigger == trigger){
            this.zIndexValue= 1001
          //   this.enteredButton = true;
            trigger.openMenu();
  
        }
        if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
          this.zIndexValue = 1001
          this.prevButtonTrigger.closeMenu();
          this.prevButtonTrigger = trigger;
          this.isMatMenuOpen = false;
          trigger.openMenu();
        }
        else if (!this.isMatMenuOpen) {
          this.enteredButton = true;
          this.prevButtonTrigger = trigger
          trigger.openMenu();
          this.zIndexValue = 1001
        }
      }, 400);
    }
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
      this.zIndexValue= 1000
  }
  
    buttonLeave(trigger, button) {
      this.zIndexValue=1001;
  
      setTimeout(() => {
        if (this.enteredButton && !this.isMatMenuOpen) {
          trigger.closeMenu();
          this.zIndexValue=1000
        }
  
      }, 400)
    }
  
    seleccionarBotonMain(numeroBoton1: any) {
      this.botonActivoMain = true;
      if (this.botonActivoMain == numeroBoton1) {
        this.botonActivoMain = false;
      } else {
        this.botonActivoMain = numeroBoton1;
      }
    }
    seleccionarBotonMain2(number4: number) {
      if (number4 == 1) {
        this.botonActivoMain2 = true;
        this.botonActivoMain3= false;
      } else if(number4 == 3) {
        this.botonActivoMain2 = false;
        this.botonActivoMain3 = true;
      } else{
        this.botonActivoMain2 = false;
        this.botonActivoMain3 = false;
      }
    }
    seleccionarBotonSub(numeroBoton2: any) {
  
      if (this.botonActivoSub == numeroBoton2) {
        this.botonActivoSub = false;
      } else {
        this.botonActivoSub = numeroBoton2;
      }
    }
    seleccionarBotonSubs(numeroBoton3: number){
  
      if(numeroBoton3 != 1){
        this.botonActivoSub = false
      }else{
        this.botonActivoSub = true;
      }
    }
    // --- Funciones para el acordeon
    toggleSubmenu() {
        this.showSubmenu = !this.showSubmenu;
    }

    toggleSubsubmenu($event) {
        $event.stopPropagation();
        this.showSubsubmenu = !this.showSubsubmenu;
    }
    toggleB() {
        this.accordionExpanded = true;
    }
    toggleA() {
        this.accordionExpanded = false;
    }

    toggleAccordion(event: Event) {
        event.stopPropagation();
        this.accordionExpanded = !this.accordionExpanded;
    }
    // ----
    isAuthenticated() {
        if (localStorage.getItem("access_token")) {
            return true;
        } else {
            return false;
        }
    }

    isDhemax(): boolean {
        return this.rGuard.isDhemax();
    }

    openBottomSheet() {
        this.bottomSheet.open(BottomSheetDescripcionComponent, {
            panelClass: "custom-width",
        });
    }

    logOut() {
        this.auth.logout();
        this.router.navigateByUrl("home");
        location.reload();
        
    }

    getLogo() {
        let personas_id = this.rGuard.personaid();
        if (personas_id) {
            this.eUserService.getAvatar(personas_id).subscribe((val) => {
                if (val.code != 400) {
                    this.imgUrl = val.img;
                } else {
                    this.imgUrl = this.baseImg;
                }
            });
        }
    }
    showRute(rute) {}

    /**
     * Creado para verificar si el usuario es de CUTSCA y puede ver sus pantallas correspondientes.
     * Podría ser usado posteriormente para hacer otras validaciones de visualización
     */
    checkPermisoUsuario = () => {
        const token = localStorage.getItem("access_token");
        const tknInfo = this.auth.jwtHelper.decodeToken(token);

        const empresa = tknInfo.empresas_id;
        const rol = tknInfo.role;

        // USUARIO CUTSCA
        if (empresa === 10 && rol === 13) {
            return "CUTCSA";
        } else {
            return "NORMAL";
        }
    };

    empresaCutcsa = () => {
        const token = localStorage.getItem("access_token");
        const tknInfo = this.auth.jwtHelper.decodeToken(token);

        return tknInfo.empresas_id;
    };

    obtenerMenuUsuario(rolId, empresaId) {

        this.rolesService.getRoleViews(rolId).subscribe((val) => {
            let role = val.data.role.find((role) => role.id == rolId);
            if (role.estado != 1) {
                this.logOut();
            }
            this.vistas = val.data.role_vistas;
            this.rolesVistas = val.data.role_vistas;
            this.vistas_usuario = this.rolesVistas.filter((items) => {
                if (items.roles_id == rolId) {
                    items.enabled = true;
                    delete items.roles_id;
                    this.menuItems.push(items);
                    return true;
                }
            });
            this.setRutasHabilitadas();
        });
    }

    setRutasHabilitadas() {
        // EQUIPOS
        this.rutasEquipos = [
            {
                nombre: "mapaCargadores",
                texto: "Mapa Cargadores",
                ruta: "/equipos-dinamicos/mapa-interactivo",
                icon: "map",
                status: this.obtenerStatusItemMenu("mapaCargadores"),
            },
            {
                nombre: "reportes",
                texto: "Reportes",
                ruta: "/equipos-dinamicos/reporte-equipos",
                icon: "description",
                status: this.obtenerStatusItemMenu("reportes"),
            },
        ];
        // GRÁFICOS
        this.rutasGraficos = [
            {
                nombre: "graficoBuses",
                texto: "Gráfico de buses",
                ruta: "/equipos-dinamicos/graficos-bus",
                icon: "insert_chart_outlined",
                status: this.obtenerStatusItemMenu("graficoBuses"),
            },
            {
                nombre: "graficoCargadores",
                texto: "Gráfico de cargadores",
                ruta: "/equipos-dinamicos/graficos-mangueras",
                icon: "insert_chart_outlined",
                status: this.obtenerStatusItemMenu("graficoCargadores"),
            },
            {
                nombre: "graficoMedidores",
                texto: "Gráfico de medidores",
                ruta: "/equipos-dinamicos/graficos-medidores",
                icon: "insert_chart_outlined",
                status: this.obtenerStatusItemMenu("graficoMedidores"),
            },
        ];

        // BUSES
        this.rutasBus = [
            {
                nombre: "playbackBuses",
                texto: "Playback Buses",
                ruta: "/equipos-dinamicos/playback",
                icon: "directions",
                status: this.obtenerStatusItemMenu("playbackBuses"),
            },
            {
                nombre: "estadoVehiculos",
                texto: "Estado Vehiculos",
                ruta: "/visor/estado-vehiculos",
                icon: "departure_board",
                status: this.obtenerStatusItemMenu("estadoVehiculos"),
            },
            {
                nombre: "monitoreoBuses",
                texto: "Monitoreo Buses",
                ruta: "/visor/monitoreo-buses",
                icon: "departure_board",
                status: this.obtenerStatusItemMenu("monitoreoBuses"),
            },
            {
                nombre: "consumoElectrico",
                texto: "Consumo Eléctrico",
                ruta: "/equipos-dinamicos/consumo-electrico-buses",
                icon: "departure_board",
                status: this.obtenerStatusItemMenu("consumoElectrico"),
            },
            {
                nombre: "dashboard",
                texto: "Dashboard",
                ruta: "/equipos-dinamicos/dashboard",
                icon: "departure_board",
                status: this.obtenerStatusItemMenu("dashboard"),
            },
        ];

        // ADMINISTRACIÓN
        this.rutasAdministracion = [
            {
                nombre: "comandos",
                texto: "Comandos",
                ruta: "/administracion/comandos",
                icon: "developer_board",
                status: this.obtenerStatusItemMenu("comandos"),
            },
            {
                nombre: "priorizacionCargadores",
                texto: "Priorización Cargadores",
                ruta: "/equipos-dinamicos/prioridad-cargadores-terminal",
                icon: "low_priority",
                status: this.obtenerStatusItemMenu("priorizacionCargadores"),
            },
        ];

        // ADMINISTRACIÓN => CONFIGURACIÓN
        this.rutasConfiguracion = [
            {
                nombre: "sistema",
                texto: "Sistema",
                ruta: "/administracion/configuracion/sistema",
                icon: "build",
                status: this.obtenerStatusItemMenu("sistema"),
            },
            {
                nombre: "usuarios",
                texto: "Usuarios",
                ruta: "/mantenedores/equipo/usuarios",
                icon: "person",
                status: this.obtenerStatusItemMenu("usuarios"),
            },
            {
                nombre: "roles-usuarios",
                texto: "Roles",
                ruta: "/administracion/roles-usuarios",
                icon: "person",
                status: this.obtenerStatusItemMenu("roles-usuarios"),
            },
        ];

        // MANTENEDORES
        this.rutasMantenedores = [
            {
                nombre: "tiposDeDatos",
                texto: "Tipos de Datos",
                ruta: "/mantenedores/tipos_datos",
                icon: "category",
                status: this.obtenerStatusItemMenu("tiposDeDatos"),
            },
            {
                nombre: "tiposDeFuncionalidades",
                texto: "Tipos de Funcionalidades",
                ruta: "/mantenedores/tipos_funcionalidades",
                icon: "category",
                status: this.obtenerStatusItemMenu("tiposDeFuncionalidades"),
            },
            {
                nombre: "direcciones",
                texto: "Direcciones",
                ruta: "/mantenedores/direcciones",
                icon: "location_on",
                status: this.obtenerStatusItemMenu("direcciones"),
            },
            {
                nombre: "tiposDeProtocolos",
                texto: "Tipos de Protocolos",
                ruta: "/mantenedores/tipos_protocolos",
                icon: "category",
                status: this.obtenerStatusItemMenu("tiposDeProtocolos"),
            },
            {
                nombre: "equipos",
                texto: "Equipos",
                ruta: "/mantenedores/equipo/equipos",
                icon: "inbox",
                status: this.obtenerStatusItemMenu("equipos"),
            },
            {
                nombre: "mantenedoresEquipos",
                texto: "Mantenedores Equipos",
                ruta: "/equipos-dinamicos/soporte-dhemax",
                icon: "category",
                status: this.obtenerStatusItemMenu("mantenedoresEquipos"),
            },
            {
                nombre: "visorComandos",
                texto: "Visor Comandos",
                ruta: "/mantenedores/visor-comandos",
                icon: "category",
                status: this.obtenerStatusItemMenu("visorComandos"),
            },
            {
                nombre: "tarjetas",
                texto: "Tarjetas",
                ruta: "/mantenedores/tarjetas",
                icon: "category",
                status: this.obtenerStatusItemMenu("tarjetas"),
            },
            {
                nombre: "cliente",
                texto: "Cliente",
                ruta: "/mantenedores/cliente",
                icon: "category",
                status: this.obtenerStatusItemMenu("cliente"),
            },
            {
                nombre: "mantenedor-charging-stations",
                texto: "Charging stations",
                ruta: "/mantenedores/mantenedor-charging-stations",
                icon: "description",
                status: this.obtenerStatusItemMenu(
                    "mantenedor-charging-stations"
                ),
            },
            {
                nombre: "mantenedor-evse",
                texto: "Evse",
                ruta: "/mantenedores/mantenedor-evse",
                icon: "description",
                status: this.obtenerStatusItemMenu("mantenedor-evse"),
            },
            {
                nombre: "lista-linea-terminal",
                texto: "Linea Terminal",
                ruta: "/mantenedores/lista-linea-terminal",
                icon: "description",
                status: this.obtenerStatusItemMenu("lista-linea-terminal"),
            },
            {
                nombre: "mantenedor-pools",
                texto: "Pools",
                ruta: "/mantenedores/mantenedor-pools",
                icon: "description",
                status: this.obtenerStatusItemMenu("mantenedor-pools"),
            },
            {
                nombre: "mantenedor-tenants",
                texto: "Tenants",
                ruta: "/mantenedores/mantenedor-tenants",
                icon: "description",
                status: this.obtenerStatusItemMenu("mantenedor-tenants"),
            },
            {
                nombre: "mantenedor-sessions",
                texto: "Sessions",
                ruta: "/mantenedores/mantenedor-sessions",
                icon: "description",
                status: this.obtenerStatusItemMenu("mantenedor-sessions"),
            },
        ];

        // SOPORTE
        this.rutasSoporte = [
            {
                nombre: "cuentaUsuario",
                texto: "Cuenta Usuario",
                ruta: "/mantenedores/equipo/credenciales-usuario",
                icon: "person",
                status: this.obtenerStatusItemMenu("cuentaUsuario"),
            },
            {
                nombre: "energia",
                texto: "Energia",
                ruta: "/mantenedores/energia-vivipra",
                icon: "category",
                status: this.obtenerStatusItemMenu("energia"),
            },
            {
                nombre: "estadoEquipos",
                texto: "Estado Equipos",
                ruta: "/equipos-dinamicos/estado-equipo",
                icon: "inbox",
                status: this.obtenerStatusItemMenu("estadoEquipos"),
            },
        ];

        // SOPORTE => PROGRAMA HORARIO
        this.rutasProgramaHorario = [
            {
                nombre: "programasHorarios",
                texto: "Programas Horarios",
                ruta: "/soporte/programas-horarios",
                icon: "ballot",
                status: this.obtenerStatusItemMenu("programasHorarios"),
            },
            {
                nombre: "visorProgramasHorarios",
                texto: "Visor Programas Horarios",
                ruta: "/soporte/visor-programas-horarios",
                icon: "ballot",
                status: this.obtenerStatusItemMenu("visorProgramasHorarios"),
            },
            {
                nombre: "precioProgramaHorarios",
                texto: "Precio Programa Horarios",
                ruta: "/soporte/costo-programa-horario",
                icon: "attach_money",
                status: this.obtenerStatusItemMenu("precioProgramaHorarios"),
            },
        ];

        // INFORMES
        this.informes = [
            {
                nombre: "informeDiario",
                texto: "Bus Diario",
                ruta: "/equipos-dinamicos/informe-diario",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeDiario"),
            },
            {
                nombre: "informeMensual",
                texto: "Bus Mensual",
                ruta: "/equipos-dinamicos/informe-mensual",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeMensual"),
            },
            {
                nombre: "informeAlarmas",
                texto: "Alarmas Cargador",
                ruta: "/equipos-dinamicos/informe-alarmas",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeAlarmas"),
            },
            {
                nombre: "informeCargasBus",
                texto: "Cargas por Bus",
                ruta: "/equipos-dinamicos/informe-cargas-bus",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeCargasBus"),
            },
            {
                nombre: "informeCargasCargador",
                texto: "Cargas por Cargador",
                ruta: "/equipos-dinamicos/informe-cargas-cargador",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeCargasCargador"),
            },
            {
                nombre: "informePerfilDemanda",
                texto: "Perfil Demanda",
                ruta: "/equipos-dinamicos/informe-perfil-demanda",
                icon: "description",
                status: this.obtenerStatusItemMenu("informePerfilDemanda"),
            },
            {
                nombre: "informeConsumoBus",
                texto: "Consumo bus",
                ruta: "/equipos-dinamicos/informe-consumo-bus",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeConsumoBus"),
            },
            {
                nombre: "informeConsumoMensualTerminal",
                texto: "Consumo Terminal",
                ruta: "/equipos-dinamicos/consumo-mensual-terminal",
                icon: "description",
                status: this.obtenerStatusItemMenu(
                    "informeConsumoMensualTerminal"
                ),
            },
            {
                nombre: "informeCargasUsuario",
                texto: "Cargas Usuario",
                ruta: "/equipos-dinamicos/cargas-usuario",
                icon: "description",
                status: this.obtenerStatusItemMenu("informeCargasUsuario"),
            },
        ];
    }

    obtenerParent(nombreParent: string) {
        if (this.menuItems.find((item) => item.parent === nombreParent)) {
            return true;
        } else {
            return false;
        }
    }

    obtenerStatusItemMenu(nombreRuta: string) {
        const item = this.menuItems.find((i) => i.nombre === nombreRuta);
        if (item) {
            return item.enabled;
        } else {
            return false;
        }
    }
}
// function ViewChild(MatExpansionPanel: typeof MatExpansionPanel): (target: NavBarComponent, propertyKey: "pannel") => void {
//   throw new Error('Function not implemented.');
// }
