import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, retry } from 'rxjs/operators';
import { globalApi } from 'src/app/globalApi';
import { ComandoAdmin } from '../../models/comandoAdmin.interface';

@Injectable({
  providedIn: 'root'
})
export class DialogCargadorService {
  uri = globalApi.uri;
  ocpp = globalApi.ocpp;
  extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
  public header: any;
  public header2: any;
  public host: any;
  public token: any;
  public options: any;
  public options2: any;
  isLoggedin: boolean;
  defaseHorario = new Date().getTimezoneOffset()
  constructor(private http: HttpClient) {

    this.token = localStorage.getItem('access_token');
    this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
    this.options = { headers: this.header };

    this.header2 = new HttpHeaders({ 'Content-Type': 'application/json', "method": "post", "Authorization": this.token });
    this.options2 = { headers: this.header };

  }

  getRolesVistas() {
    return this.http.get(this.uri + "/rolesVistas", this.options);
  }
  getTerminales() {

    return this.http.get(this.uri + "/terminales", this.options).pipe(
      retry(4),
      timeout(20000)
    )
  }

  getTerminalesPorEmpresa(idEmpresa): Observable<any> {

    return this.http.post(this.uri + "/terminalesPorEmpresa", idEmpresa, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  terminalesDhemax(empresa) {

    return this.http.get(this.uri + "/terminalesDhemax/" + empresa, this.options).pipe(
      retry(4),
      timeout(20000)
    )
  }
  estadocargadorBYD(id) {
    return this.http.get(this.uri + "/estadocargadorBYD/" + id, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getEmpresa(empresa_id): Observable<any> {
    this.refreshToken();
    return this.http.get(this.uri + "/empresas/" + empresa_id, this.options).pipe(
      timeout(10000)
    )
  }

  getEmpresas(): Observable<any> {
    this.refreshToken();
    return this.http.get(this.uri + "/empresas", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getLineas(id): Observable<any> {
    return this.http.get(this.uri + "/lineasTerminal/" + id, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  getMangueraAlarmaData(data): Observable<any> {
    return this.http.post(this.uri + "/faultedMangueras", data, this.options);
  }

   getGeneradorLinea(id): Observable<any> {
    return this.http.get(this.uri + "/nodos_lineas?lineas_id=" + id, this.options).pipe(
      retry(4)
    )
  }
  fullCargadoresPorEmpresa(data): Observable<any> {
    return this.http.post(this.uri + "/fullCargadoresPorEmpresa", this.options)
  }
  cargadoresMuevo(data): Observable<any> {
    return this.http.post(this.uri + "/cargadoresMuevo", data, this.options)
  }

  getEquiposSinTerminal(): Observable<any> {
    return this.http.get(this.uri + "/equiposSinTerminal", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  getEquiposSinTerminalMantenedorl(): Observable<any> {
    return this.http.get(this.uri + "/equiposSinTerminalMantenedor", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }


  getEquiposSinTerminalDhemax(id): Observable<any> {
    return this.http.get(this.uri + "/equiposSinTerminalDhemax/" + id, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getEquiposSinTerminalDhemaxMantenedor(id): Observable<any> {
    return this.http.get(this.uri + "/equiposSinTerminalDhemaxMantenedor/" + id, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  getEquiposBus(id): Observable<any> {
    return this.http.get(this.uri + "/equipoBus/" + id, this.options).pipe(
      retry(4),
      timeout(100000)
    )
  }
  getAllBus(): Observable<any> {
    return this.http.get(this.uri + "/equiposAllBus/", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  BusEmpresa(): Observable<any> {
    return this.http.get(this.uri + "/BusEmpresa", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  nuevaLineaTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/nuevaLineaTerminal", data, this.options)
  }

  cambioEquipoLinea(data: any): Observable<any> {
    return this.http.post(this.uri + "/cambioEquipoLinea", data, this.options)
  }
  getManguerasEquipo(data: any): Observable<any> {
    return this.http.post(this.uri + "/getManguerasEquipo", data, this.options)
  }
  setManguerasEquipo(data: any): Observable<any> {
    return this.http.post(this.uri + "/setManguerasEquipo", data, this.options)
  }
  getTipoElectrico(): Observable<any> {
    return this.http.get(this.uri + "/getTipoElectrico", this.options)
  }
  datosAlarmasManguerasCargasUsuario(data): Observable<any> {
    return this.http.post(this.uri + "/datosAlarmasManguerasCargasUsuario", data, this.options)
  }

  //ADD EQUIPO
  addEquipoData(): Observable<any> {
    return this.http.get(this.uri + "/addEquipoData", this.options)
  }
  addDireccion(data): Observable<any> {
    return this.http.post(this.uri + "/addDireccion", data, this.options)
  }
  addTerminal(data): Observable<any> {
    return this.http.post(this.uri + "/addTerminal", data, this.options)
  }
  addLinea(data): Observable<any> {
    return this.http.post(this.uri + "/addLinea", data, this.options)
  }
  //END ADD EQUIPO

  //MUEVO
  insertChargingStations(data): Observable<any> {
    return this.http.post(this.uri + "/insertChargingStations", data, this.options)
  }
  updateChargingStations(data): Observable<any> {
    return this.http.post(this.uri + "/updateChargingStations", data, this.options)
  }
  updateEvseAndConnector(data): Observable<any> {
    return this.http.post(this.uri + "/updateEvseAndConnector", data, this.options)
  }
  insertEvse(data): Observable<any> {
    return this.http.post(this.uri + "/insertEvse", data, this.options)
  }
  getTenants(): Observable<any> {
    return this.http.get(this.uri + "/getTenants", this.options)
  }
  getSessions(): Observable<any> {
    return this.http.get(this.uri + "/getSessions", this.options)
  }
  getChargingStations(): Observable<any> {
    return this.http.get(this.uri + "/getChargingStations", this.options)
  }
  getEvsesAndConnectors(data): Observable<any> {
    return this.http.post(this.uri + "/getEvsesAndConnectors", data, this.options)
  }
  getOcpiLocations(): Observable<any> {
    return this.http.get(this.uri + "/getOcpiLocations", this.options)
  }
  updateLocations(data): Observable<any> {
    return this.http.post(this.uri + "/updateLocations", data, this.options)
  }
  insertLocations(data): Observable<any> {
    return this.http.post(this.uri + "/insertLocations", data, this.options)
  }
  getAllPools(): Observable<any> {
    return this.http.get(this.uri + "/getAllPools", this.options)
  }
  getPoolByChargingStationID(data): Observable<any> {
    return this.http.post(this.uri + "/getPoolByChargingStationID", data, this.options)
  }
  insertPool(data): Observable<any> {
    return this.http.post(this.uri + "/insertPool", data, this.options)
  }
  insertTenants(data): Observable<any> {
    return this.http.post(this.uri + "/insertTenants", data, this.options)
  }
  updateTenants(data): Observable<any> {
    return this.http.post(this.uri + "/updateTenants", data, this.options)
  }

  //END MUEVO

  //FLIPPY INFORMES
  /**
   *
   * @param data = {cargadores:[1,2,3],start_date('YYYY-mm-dd HH:mm:ss),end_date('YYYY-mm-dd HH:mm:ss)}
   * @returns huge array with basically ANYTHING you would need about it
   */
  informeCargasCargador(data): Observable<any> {
    return this.http.post(this.uri + "/informeCargasCargador", data, this.options)
  }
  //END FLIPPY INFORMES

  getTiposCables() {
    return this.http.get(this.uri + "/tiposCables", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }


  getMangueras(): Observable<any> {
    return this.http.get(this.uri + "/mangueras", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }


  getComunas(): Observable<any> {
    return this.http.get(this.uri + "/getComunas", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getRegiones(): Observable<any> {
    return this.http.get(this.uri + "/getRegiones", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getMangueraUnica(id): Observable<any> {
    return this.http.get(this.uri + "/mangueras/" + id, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  // usar este
  getDataGrafico(equipo, tipo, timezone, manguera): Observable<any> {
    return this.http.get(this.uri + "/graficosId/" + equipo + "/" + tipo + '/' + timezone + '/' + manguera, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  getDataGraficoOcpp(equipo, tipo, timezone, manguera): Observable<any> {
    return this.http.get(this.uri + "/graphOcpp/" + equipo + "/" + tipo + '/' + timezone + '/' + manguera, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getDataGraficoBYD(equipo, tipo, timezone): Observable<any> {
    return this.http.get(this.uri + "/graficosBYD/" + equipo + "/" + tipo + '/' + timezone, this.options).pipe(
      retry(2),
      timeout(5000)
    )
  }

  envioComandoCargador(data): Observable<any> {
    return this.http.post(this.uri + "/envioComandoCargador", data, this.options)
  }

  // Fin carga
  envioComandoOcppStop(data): Observable<any> {
    return this.http.post(this.uri + "/remoteStopNuevo", data, this.options)
  }

  // Inicio carga
  envioComandoOcppStart(data): Observable<any> {
    return this.http.post(this.uri + "/remoteStartFull", data, this.options)/* .pipe(
            retry(4),
            timeout(10000)
        ) */

  }
  remoteRestart(data): Observable<any> {
    return this.http.post(this.uri + "/remoteRestart", data, this.options);
  }


  envioComandoStatusCargador(data): Observable<any> {

    /* Formato req
     {
         machineID: '0012',
         connectorID: 2,
         type: 'Inoperative'
     }
     */

    return this.http.post(this.uri + "/envioComandoStatusCargador", data, this.options);
    // return this.http.post(this.ocpp + "/api/v1/machine/availability/change", data);
  }

  envioComandoOcppStartLinea(data): Observable<any> {
    return null;
    // return this.http.post(this.ocpp + "/api/v1/machine/RemoteStart/list", data).pipe(
    //   retry(4),
    //   timeout(30000)
    // )

  }

  envioComandoOcppStopLinea(data): Observable<any> {
    return null;
    // return this.http.post(this.ocpp + "/api/v1/machine/RemoteStop/list", data).pipe(
    //   retry(4),
    //   timeout(30000)
    // )

  }

  // Comando ByD Carga Cargador
  enviarComandoCargaCargador(data): Observable<any> {
    return this.http.post(this.uri + "/envioComandoCargador", data, this.options).pipe(
      timeout(10000)
    )
  }

  transaccionID(data): Observable<any> {
    return this.http.post(this.uri + "/transaccionManguera", data, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  EnviarcomandoTecle(data): Observable<any> {
    return this.http.post(this.uri + "/tecle", data, this.options)
  }

  //COMANDO ADMIN TRIGGER
  comandoAdminTrigger(data: ComandoAdmin): Observable<any> {
    return this.http.get(this.uri + `/comandoAdminTrigger?machineID=${data.machineID}&connectorID=${data.connectorID}&requestedMessage=${data.requestedMessage}`, this.options);
  }


  getEquipos(): Observable<any> {
    return this.http.get(this.uri + "/equiposHabilitados", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getEquiposBusHabilitados(): Observable<any> {
    return this.http.get(this.uri + "/equiposHabilitadosBus", this.options).pipe(
      retry(4),
      timeout(100000)
    )
  }

  getEquiposBusHabilitadosAdmin(id): Observable<any> {
    return this.http.post(this.uri + "/busGraficosAdmin", { id }, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getCargadoresManguera(): Observable<any> {
    return this.http.get(this.uri + "/CargadoresManguera", this.options).pipe(
      retry(4),
      timeout(25000)
    )
  }

  getEquiposEmpresa(): Observable<any> {
    return this.http.get(this.uri + "/getAllHabilitadosEmpresa", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getAllHabilitadosEmpresaAdmin(data): Observable<any> {
    return this.http.post(this.uri + "/getAllHabilitadosAdmin", data, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getBusGrafico(): Observable<any> {
    return this.http.get(this.uri + "/busGraficos", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getBusGraficoEmpresaAdmin(data): Observable<any> {
    return this.http.post(this.uri + "/busGraficosAdmin", data, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  consumoElectricoBus(data): Observable<any> {
    return this.http.post(this.uri + "/consumoElectricoBus", data, this.options).pipe(
      retry(4),
      timeout(100000)
    )
  }
  consumoElectricoBusKauffman(data): Observable<any> {
    return this.http.post(this.uri + "/consumoElectricoBusKauffman", data, this.options).pipe(
      retry(4),
      timeout(100000)
    )
  }
  monitoreoBus(data): Observable<any> {
    return this.http.post(this.uri + "/monitoreoCanAdmin", data, this.options).pipe(
      retry(4),
      timeout(100000)
    )
  }
  getMonitoreoBus(): Observable<any> {
    return this.http.get(this.uri + "/monitoreoCan", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }


  getEquiposTipo(data): Observable<any> {
    return this.http.post(this.uri + "/equiposTipos", data, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }


  getUBB(): Observable<any> {
    return this.http.get(this.uri + "/UUBB", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  nuevoTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/nuevoTerminal", data, this.options)
  }

  nuevoEquipo(data: any): Observable<any> {
    return this.http.post(this.uri + "/nuevoEquipo", data, this.options)
  }

  nuevoEquipoSinTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/nuevoEquipoSinTerminal", data, this.options)
  }

  nuevoEquipoConTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/nuevoEquipoConTerminal", data, this.options)
  }

  editaEquipo(data: any): Observable<any> {
    return this.http.post(this.uri + "/editaEquipo", data, this.options)
  }


  getUbicaciones(): Observable<any> {
    return this.http.get(this.uri + "/ubicaciones", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getTipoEquipos(): Observable<any> {
    return this.http.get(this.uri + "/tiposEquipos", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }

  getTipoProtocolo(): Observable<any> {
    return this.http.get(this.uri + "/tipos_protocolo", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  getTipoFuncionalidades(): Observable<any> {
    return this.http.get(this.uri + "/tiposFuncionalidades", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }


  updateSinTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/editarSinTerminal", data, this.options)
  }
  updateConTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/editarConterminal", data, this.options)
  }

  updateTerminal(data: any): Observable<any> {
    return this.http.post(this.uri + "/editarTerminal", data, this.options)
  }
  // por hacer  y revisar
  getTiposDatos(): Observable<any> {
    return this.http.get(this.uri + "/tiposDatos", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  TipoDatosProtocolo(data): Observable<any> {
    return this.http.post(this.uri + "/tiposDatosProtocolo", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  OrigenTiposDatos(data): Observable<any> {
    return this.http.post(this.uri + "/origenTiposDatos", data, this.options)
  }
  getFiltroDatosMultiple(data, tipo1, tipo2): any {
    return this.http.get(this.uri + "/getFiltroDatosMultiple/" + data.equipo + "/" + tipo1 + "/" + tipo2 + "/" + data.fecha + "/" + this.defaseHorario / 60, this.options)

  }
  getFiltroDatosMultipleBus(data): Observable<any> {
    return this.http.post(this.uri + "/datosFiltroMultipleBus", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }

  getFiltroDatosMultipleKinglog(data, tipo1, tipo2): any {
    return this.http.get(this.uri + "/getFiltroDatosMultipleKinglon/" + data.equipo + "/" + tipo1 + "/" + tipo2 + "/" + data.fecha + "/" + this.defaseHorario / 60, this.options)

  }

  getInicioFinCarga(data): any {

    return this.http.get(this.uri + "/getInicioFinCarga/" + data.equipo + "/" + data.fecha, this.options)

  }

  getLogsFiltro(data, tipo): any {

    return this.http.get(this.uri + "/getFiltroDatos/" + data.equipo + "/" + tipo + "/" + data.fecha + "/" + this.defaseHorario / 60, this.options)

  }
  getLogsFiltroBus(data): Observable<any> {
    return this.http.post(this.uri + "/datosFiltroBus", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }


  getLogsFiltroOcpp(data, tipo): any {

    return this.http.get(this.uri + "/getFiltroDatosOcpp/" + data.equipo + "/" + tipo + "/" + data.fecha + "/" + this.defaseHorario / 60, this.options)

  }

  getLogsFiltroKinglon(data, tipo): any {

    return this.http.get(this.uri + "/getFiltroDatosKinglon/" + data.equipo + "/" + tipo + "/" + data.fecha + "/" + this.defaseHorario / 60, this.options)

  }
  mediciones_bus(data): Observable<any> {
    return this.http.post(this.uri + "/mediciones_bus", data, this.options).pipe(
      retry(4),
      timeout(3000000)
    )
  }
  carga_cgs(data): Observable<any> {
    return this.http.post(this.uri + "/carga_cgs", data, this.options).pipe(
      retry(4),
      timeout(3000000)
    )
  }
  capacidad_carga(data): Observable<any> {
    return this.http.post(this.uri + "/capacidadDeCarga", data, this.options).pipe(
      retry(4),
      timeout(3000000)
    )
  }
  velocidadVehiculo(data): Observable<any> {
    return this.http.post(this.uri + "/velocidadVehiculo", data, this.options).pipe(
      retry(4),
      timeout(3000000)
    )
  }
  // REPORTES
  datosReporteCargador(data): Observable<any> {
    return this.http.post(this.uri + "/datosReporteCargador", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  datosReporteBus(data): Observable<any> {
    return this.http.post(this.uri + "/datosReporteBus", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  equiposReporte(): Observable<any> {
    return this.http.get(this.uri + "/equiposReporte", this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  equiposReporteAdmin(data): Observable<any> {
    return this.http.post(this.uri + "/equiposReporteAdmin", data, this.options).pipe(
      retry(4),
      timeout(10000)
    )
  }
  reporteGeneral(data): Observable<any> {
    return this.http.post(this.uri + "/datosReporteGeneral", data, this.options);
  }

  // INFORMES
  datosReporteCargaBus(data): Observable<any> {
    return this.http.post(this.uri + "/datosInformeCargaBus", data, this.options).pipe(
      retry(4)
    );
  }

  datosReporteConsumoBus(data): Observable<any> {
    return this.http.post(this.uri + "/datosConsumoBus", data, this.options)
  }
  getSubEmpresasByEmpresaID(data): Observable<any> {
    return this.http.post(this.uri + "/getSubEmpresasByEmpresaID", data, this.options)
  }
  getDatosUsuariosParaSsts(data): Observable<any> {
    return this.http.post(this.uri + "/getDatosUsuariosParaSsts", data, this.options)
  }

  datosReporteConsumoMensualTemporal(data): Observable<any> {
    return this.http.post(this.uri + "/datosConsumoMensualTemporal", data, this.options)
  }


  datosReporteCargaCargador(data): Observable<any> {
    return this.http.post(this.uri + "/datosInformeCargaCargador", data, this.options).pipe(
      retry(4)
    );
  }

  datosPerfilDemanda(data): Observable<any> {
    return this.http.post(this.uri + "/datosInformePerfilDemanda", data, this.options).pipe(
      retry(4)
    );
  }

  datosInformeBusAlarmas(data): Observable<any> {
    return this.http.post(this.uri + "/getAlarmasEquipo", data, this.options).pipe(
      retry(4)
    );
  }
  datosReporteBusAlarmasCodigos(data): Observable<any> {
    return this.http.post(this.uri + "/obtenerAlarmasDatatransfersPorFecha", data, this.options).pipe(
      retry(4)
    );
  }

  datosReporteBusDiario(data): Observable<any> {
    return this.http.post(this.uri + "/busDiario", data, this.options).pipe(
      retry(4)
    );
  }

  datosReporteBusMensual(data): Observable<any> {
    return this.http.post(this.uri + "/busMensual", data, this.options)
  }

  mailInforme(data): Observable<any> {
    return this.http.post('https://pge.dhemax.cl:3005' + '/mailerInforme', data, this.options);
  }

  datosMedidoresUnico(data): Observable<any> {

    return this.http.post(this.uri + "/datosMedidoresUnico", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }

  // mangueras

  datosMangueraUnico(data): Observable<any> {

    return this.http.post(this.uri + "/datosMangueraUnico", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  datosMangueraMultiple(data): Observable<any> {
    return this.http.post(this.uri + "/datosMangueraMultiple", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  CargadoresMangueraAdmin(data): Observable<any> {
    return this.http.post(this.uri + "/CargadoresMangueraAdmin", data, this.options).pipe(
      retry(4),
      timeout(50000)
    )
  }
  datosCargadorVivipraRFID(data): Observable<any> {
    return this.http.post(this.uri + "/datosCargadorVivipraRFID", data, this.options)
  }

  datosCargadorVivipraRFID2(data): Observable<any> {
    return this.http.post(this.uri + "/datosCargadorVivipraRFID2", data, this.options)
  }
  datosCargadorVivipraRFID3(data): Observable<any> {
    return this.http.post(this.uri + "/datosCargadorVivipraRFID3", data, this.options)
  }
  datosCargadorVivipraRFID4(data): Observable<any> {
    return this.http.post(this.uri + "/datosCargadorVivipraRFID4", data, this.options)
  }
  getClientesRFID(data): Observable<any> {
    return this.http.post(this.uri + "/getClientesRFID", data, this.options)
  }
  CargadoresManguera(): Observable<any> {
    return this.http.get(this.uri + "/CargadoresManguera", this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  cargadoresEmpresaByID(data): Observable<any> {
    return this.http.post(this.uri + "/cargadoresEmpresaByID", data, this.options);
  }


  getTipo_Prioridades(): any {
    return this.http.get(this.uri + "/tipos_prioridades/", this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  postPrioridadesCargadores(data: any): Observable<any> {
    return this.http.post(this.uri + "/actualizar_prioridades_cargadores", data, this.options).pipe(
      retry(2),
      timeout(30000)
    )
  }
  postPrioridadesCgsExclusivo(data: any): Observable<any> {
    return this.http.post(this.uri + "/actualizar_prioridades_cgs_exclusivo", data, this.options).pipe(
      retry(2),
      timeout(30000)
    )
  }
  getConfigPrioridadesCgs(id): any {
    return this.http.get(this.uri + "/config_prioridades/" + id, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  getGps(data): any {
    return this.http.post(this.uri + "/gpsBus", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }
  datos_playback(data): any {
    return this.http.post(this.uri + "/datos_playback", data, this.options).pipe(
      retry(4),
      timeout(30000)
    )
  }

  processGPSString(list) {
    for (var i = 0; i < list.length; i++) {
      var tmpLat = parseFloat(list[i].latitud)
      var tmpLon = parseFloat(list[i].longitud)
      list[i].latitud = tmpLat;
      list[i].longitud = tmpLon;
      list[i].check = false;
    }
    return list;
  }
  normalizacion(data, tipo_dato) {
    var dato = ["hola"]
    var normalizacion = tipo_dato.tipos_datos_normalizacion
    normalizacion.forEach(x => {
      switch (x.tipos_normalizacion_id) {
        case 1:
          dato = this.rango_valores(data, x)
          break
        case 2:
          break
      }
    });
    return dato
  }
  rango_valores(data, normalizacion) {
    var datos = []

    normalizacion.b = Number(normalizacion.b)
    normalizacion.a = Number(normalizacion.a)
    data.forEach(x => {
      if (x.valor >= normalizacion.a && x.valor < normalizacion.b) {
        datos.push(x)
      }
    });

    return datos
  }

  validaDistanciaGPS(gps, distancia) {

    for (let i = 0; i < gps.length - 1; i++) {
      if (this.distanciaEntrePuntos(gps[i].latitud, gps[i].longitud, gps[i + 1].latitud, gps[i + 1].longitud) > distancia) {
        gps[i].check = true;
        gps[i + 1].check = true;
      }
    }
  }
  distanciaEntrePuntos(lat1, lon1, lat2, lon2) {
    var distancia = Math.sqrt(Math.pow((lat2 - lat1), 2) + Math.pow((lon2 - lon1), 2))
    return distancia;
  }

  //INFORME CARGAS USUARIO
  getSubEmpresasIDPadre(data): Observable<any> {
    return this.http.post(this.uri + "/getSubEmpresasIDPadre", data, this.options)
  }
  registroUsuario(data): Observable<any> {
    return this.http.post(this.uri + "/registroUsuario", data, this.options)
  }
  insertPersona(data): Observable<any> {
    return this.http.post(this.uri + "/insertPersona", data, this.options)
  }
  getUsuariosEmpresaID(data): Observable<any> {
    return this.http.post(this.uri + "/getUsuariosEmpresaID", data, this.options)
  }
  insertUsuarioAccesoCarga(data): Observable<any> {
    return this.http.post(this.uri + "/insertUsuarioAccesoCarga", data, this.options)
  }
  getTiposAcceso(data): Observable<any> {
    return this.http.post(this.uri + "/getTiposAcceso", data, this.options)
  }
  getUsuarioAccesosCarga(data): Observable<any> {
    return this.http.post(this.uri + "/getUsuarioAccesosCarga", data, this.options)
  }
  getPaises(data): Observable<any> {
    return this.http.post(this.uri + "/getPaises", data, this.options)
  }
  getStartStopTransaction(data): Observable<any> {
    return this.http.post(this.uri + "/getStartStopTransaction", data, this.options)
  }
  getStartStopTransactionBETTER(data): Observable<any> {
    return this.http.post(this.uri + "/getStartStopTransactionBETTER", data, this.options)
  }
  startStopPorManguera(data): Observable<any> {
    return this.http.post(this.uri + "/startStopPorManguera", data, this.options)
  }
  getDatosOcppTransactionID(data): Observable<any> {
    return this.http.post(this.uri + "/getDatosOcppTransactionID", data, this.options)
  }
  informeCargasUsuario(data): Observable<any> {
    return this.http.post(this.uri + "/informeCargasUsuario", data, this.options)
  }
  getDatosOcppPorTidYManguera(data): Observable<any> {
    return this.http.post(this.uri + "/getDatosOcppPorTidYManguera", data, this.options)
  }
  getEquiposPorMangueras(data): Observable<any> {
    return this.http.post(this.uri + "/getEquiposPorMangueras", data, this.options)
  }

  refreshToken(){
    this.token = localStorage.getItem('access_token');
    this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
    this.options = { headers: this.header };
}
}
