
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class EmitService {
    // Observable string sources
    private emitChangeSource = new Subject<any>();

    private emitToNavBarChangeSource = new Subject<any>();

    private returnEmitChangeSource = new Subject<any>();
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();

    changeNavBarEmitted$ = this.emitToNavBarChangeSource.asObservable();

    ReturnChangeEmitted$ = this.returnEmitChangeSource.asObservable();
    // Service message commands




    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    emitToNavBarChange(change: any) {
        this.emitToNavBarChangeSource.next(change);
    }


    returnChange(change: any) {
        this.returnEmitChangeSource.next(change);
    }
}