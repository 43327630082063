import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoDatoPipe',

})
export class TipoDatoPipe implements PipeTransform {
  constructor(){}
  transform(value: string, args?: any): string {
    let lowervalue = value.toLowerCase();
    let result = lowervalue.split('_').join(' ').split('ft').join(' ');

    let words = result.split(' ');
    for (var i = 0; i < words.length; i++) {
      if(words[i].toLowerCase() == 'soc' ||
        words[i].toLowerCase() == 'soh' ||
        words[i].toLowerCase() == 'ac' ||
        words[i].toLowerCase() == 'av' 
        ){
          words[i] = words[i].toUpperCase();
      }else{
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
    }
    let returnStr = words.join(" ");
    return returnStr;
}

}
