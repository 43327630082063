import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { Ng2Rut } from 'ng2-rut';
import { AgmCoreModule } from '@agm/core';
import { MapActualComponent } from './sharedDialog/map-actual/map-actual.component';
import { GeocodeService } from './sharedServices/geocode.service';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { characterReplacer } from './sharedPipes/characterReplacer.pipe';
import { ConfirmDialogComponent } from './sharedDialog/confirm-dialog/confirm-dialog.component';
import { UbicacionActualEquipoComponent } from './sharedDialog/ubicacion-actual-equipo/ubicacion-actual-equipo.component';
import { CeroAGuionPipe } from './sharedPipes/cero-aguion.pipe';
import { TipoDatoPipe } from './sharedPipes/tipo-dato-pipe.pipe';
import { TableTemplateComponent } from './sharedComponents/table-template/table-template.component';
import { NullReplacerPipe } from './sharedPipes/null-replacer.pipe';
import { SelectorEmpresaComponent } from './components/selector-empresa/selector-empresa.component';

@NgModule({
    declarations: [
        characterReplacer,
        CeroAGuionPipe,
        TipoDatoPipe,
        MapActualComponent,
        ConfirmDialogComponent,
        UbicacionActualEquipoComponent,
        TableTemplateComponent,
        NullReplacerPipe,
        SelectorEmpresaComponent,
    ],
    imports: [
        MaterialModule,
        CommonModule,
        Ng2Rut,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBKLZBT4LdpnY7qyaYB8hYPIKnKJy6eFlg',
            //apiKey: 'AIzaSyDouJncyRWt29-PSe_RTjKXOsFeOOLJDmM',
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule.forRoot()
    ],
    exports: [
        characterReplacer,
        Ng2Rut,
        AgmCoreModule,
        MatGoogleMapsAutocompleteModule,
        CeroAGuionPipe,
        TipoDatoPipe,
        TableTemplateComponent,
        NullReplacerPipe,
        SelectorEmpresaComponent,
    ],
    entryComponents: [
        MapActualComponent,
        UbicacionActualEquipoComponent,
        ConfirmDialogComponent
    ],
    providers: [GeocodeService]

})
export class SharedFormModule { }
