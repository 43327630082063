import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullReplacer'
})
export class NullReplacerPipe implements PipeTransform {

  transform(value: any, returnValue:string): any {
    if(value != null || value != " " || value != undefined || value != ""){

      return value;
    }
    return returnValue;
  }

}
